import ScheduleSectionItemFormController from './schedule_section_item_form_controller';

export default class extends ScheduleSectionItemFormController {
  static targets = ["categoryInput", "categorySelected"];

  connect() {
    super.connect()
    this.specAttributeName = 'library_product'
  }

  selectCategory(event) {
    this.categoryInputTarget.value = event.target.dataset.value;
    this.categorySelectedTarget.innerHTML = event.target.innerHTML;
    this.submit(event);
  }

  next(event) {
    const el = event.target;
    const nextOptions = el.closest('.option-wrapper').nextElementSibling

    Array.from(el.closest('.options-level').children).forEach(child => {
      if (this.containClass(child, ['option-wrapper', 'option-back'])) {
        child.classList.add('hidden')
      }
    })

    if (nextOptions.classList.contains('options-level')) {
      Array.from(nextOptions.children).forEach(child => {
        if (this.containClass(child, ['option-wrapper', 'option-back'])) {
          child.classList.remove('hidden')
        }
      })
    }
  }

  back(event) {
    const currentOptions = event.target.closest('.options-level');
    const parentOptions = currentOptions.parentElement.closest('.options-level');

    Array.from(currentOptions.children).forEach(child => {
      if (this.containClass(child, ['option-wrapper', 'option-back'])) {
        child.classList.add('hidden')
      }
    })

    Array.from(parentOptions.children).forEach(child => {
      if (this.containClass(child, ['option-wrapper', 'option-back'])) {
        child.classList.remove('hidden')
      }
    })
  }

  containClass(el, cssClass) {
    if (Array.isArray(cssClass)) {
      return Array.from(el.classList).some(cls => cssClass.includes(cls))
    }
    return el.classList.contains(cssClass)
  }
}
